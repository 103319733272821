<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <titleCard title="Horarios de entrega" subtitle="Para compras en el mismo día"></titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap text-xs-center>
            <v-flex xs12 sm6 class="text-xs-left">
              <v-subheader>
                Pedidos de
                <span class="font-weight-black ml-1"> Lunes a Viernes </span>
                <v-spacer></v-spacer>
                <v-btn class="mx-0" color="secondary" icon small @click="addSchedule('day')">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-subheader>
              <v-divider></v-divider>
              <schedule-list
                :type="'day'"
                :delete-action="deleteAction"
                :get-selected-schedule="getSelectedSchedule"
                :schedules="day"
              >
              </schedule-list>
            </v-flex>
            <v-flex xs12 sm6 class="text-xs-left">
              <v-subheader>
                Pedidos de
                <span class="font-weight-black ml-1"> Sabado y Domingos </span>
                <v-spacer></v-spacer>
                <v-btn class="mx-0" color="secondary" icon small @click="addSchedule('fds')">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-subheader>
              <v-divider></v-divider>
              <schedule-list
                :type="'fds'"
                :delete-action="deleteAction"
                :get-selected-schedule="getSelectedSchedule"
                :schedules="fds"
              >
              </schedule-list>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <titleCard title="Horarios de entrega" subtitle="Para pedidos programados con anticipación"> </titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap text-xs-center>
            <template v-if="days.length > 0">
              <v-flex
                v-for="(day, key) in days"
                :key="key"
                xs12
                sm6
                md6
                :lg3="key != 6"
                :lg6="key == 6"
                class="text-xs-left"
              >
                <v-subheader>
                  {{ getDay(day.day) }}
                  <v-spacer></v-spacer>
                  <v-btn class="mx-0" color="secondary" icon small @click="addSchedule(day.day, day.shedules)">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-subheader>
                <v-divider></v-divider>
                <schedule-list
                  :type="day.day"
                  :delete-action="deleteAction"
                  :get-selected-schedule="getSelectedSchedule"
                  :schedules="day.shedules"
                >
                </schedule-list>
              </v-flex>
            </template>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialogNewSchedule" scrollable persistent max-width="400px">
      <v-card>
        <title-dialog
          title="Horarios disponibles"
          close
          :close-action="() => (dialogNewSchedule = false)"
        ></title-dialog>
        <v-card-text class="py-0 mb-0 px-0" style="height: 400px;">
          <template v-for="(schedule, index) in schedules">
            <v-checkbox :key="index" v-model="schedulesSelected" class="my-2 px-3" :value="schedule.id" hide-details>
              <div slot="label" class="pl-2">
                <span v-html="schedule.name"></span><br />
                <span class="caption">
                  Cierre L-V: {{ schedule.hour_day }} / S-D:
                  {{ schedule.hour_fds }}
                </span>
              </div>
            </v-checkbox>
            <v-divider :key="`d-${index}`"></v-divider>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="default" flat @click="dialogNewSchedule = false">Volver</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" depressed @click="updateSchedule">Actualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import titleCard from '../useful/titleCard.vue'
import titleDialog from '../useful/titleDialog.vue'
import scheduleList from './scheduleList.vue'
import { GET_SCHEDULE_STORE } from '../../config'

export default {
  name: 'StoreStates',
  components: { titleCard, titleDialog, scheduleList },
  data: () => ({
    loading: false,
    menu: false,
    day: [],
    days: [],
    fds: [],
    dialogNewSchedule: false,
    schedulesSelected: [],
    schedulesRemove: 0,
    daysList: [
      { id: 1, name: 'Domingo' },
      { id: 2, name: 'Lunes' },
      { id: 3, name: 'Martes' },
      { id: 4, name: 'Miercoles ' },
      { id: 5, name: 'Jueves' },
      { id: 6, name: 'Viernes' },
      { id: 7, name: 'Sabado' }
    ]
  }),
  computed: {
    schedules() {
      return this.$store.getters.getSchedules
    }
  },
  mounted() {
    this.getSchedule()
  },
  methods: {
    async getSchedule() {
      try {
        const res = await this.$http.get(GET_SCHEDULE_STORE)
        this.day = res.data.day
        this.days = res.data.days
        this.fds = res.data.fds
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async updateSchedule() {
      try {
        await this.$http.post(GET_SCHEDULE_STORE, {
          type: this.typeSelected,
          schedules: this.schedulesSelected
        })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Horario acualizado'
        })
        this.getSchedule()
        this.dialogNewSchedule = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async deleteSchedule(type, schedule, schedules) {
      await this.$http.delete(
        `${GET_SCHEDULE_STORE}?${this.$qs.stringify({
          type,
          schedule,
          schedules
        })}`
      )
      this.$store.dispatch('setConfirm', {
        active: false,
        title: '',
        description: '',
        action: {}
      })
      this.$store.dispatch('changeSnack', {
        active: true,
        text: 'Horario eliminado'
      })
      this.getSchedule()
    },
    addSchedule(type, schedules) {
      this.dialogNewSchedule = true
      if (type === 'day') {
        this.schedulesSelected = this.day
      } else if (type === 'fds') {
        this.schedulesSelected = this.fds
      } else if (type > 0 || type < 7) {
        this.schedulesSelected = schedules
      }
      this.typeSelected = type
    },
    getDay(id) {
      const day = this._.find(this.daysList, ['id', id])
      return day.name
    },
    getSelectedSchedule(schedules) {
      const resp = []
      schedules.forEach(element => {
        resp.push(this._.find(this.schedules, ['id', element]))
      })
      return resp
    },
    deleteAction(type, schedule, schedules) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Eliminar horario?',
        description: '',
        action: () => this.deleteSchedule(type, schedule, schedules)
      })
    }
  }
}
</script>

<style></style>
